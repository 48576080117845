<template>
  <div class="grey lighten-4">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined tile class="custom__card">
            <h2 class="text-center text-h2 mt-12 white--text font-weight-thin">
              About <span class="warning--text font-weight-light">Us</span>
            </h2>
            <!-- <v-img height="200" src="../assets/ginger2.jpeg"></v-img> -->
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined tile min-height="50vh">
            <h2
              class="text-h6 font-weight-light pt-2 grey--text text--darken-3 text-center custom__heading-green"
            >
              OUR TEAM
            </h2>
            <v-row no-gutters justify="center">
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/Manzo Maigari.jpg">
                  </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Dr. Manzo Maigari
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Chief Executive Officer
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/cto.jpeg"> </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Isah Raphael
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Chief Technology Officer
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/warehouse.jpeg"> </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Yashim Samuel
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Warehouse Manager
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters justify="start">
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/admin.jpeg"> </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Israella Enyi
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Admin Manager
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/consultant.jpeg"> </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Prof. Sambo Banake
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Consultant Agronomist
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="d-flex flex-column align-center"
              >
                <v-avatar size="200">
                  <v-img height="100%" src="../assets/operations.jpeg"> </v-img
                ></v-avatar>
                <p class="mb-0 mt-2 grey--text text--darken-3 text-center">
                  Engr. Aaron Shekarau
                </p>
                <p class="grey--text text--darken-1 text-center">
                  Operations Manager
                </p>
              </v-col>
            </v-row>
            <h2
              class="text-h6 font-weight-light pt-4 grey--text text--darken-3 text-center custom__heading-orange"
            >
              WHO WE ARE & WHAT WE DO
            </h2>
            <v-row>
              <v-col cols="12" md="7">
                <p
                  class="px-16 text-body-1 text-md-h6 font-weight-light grey--text text--darken-3"
                >
                  Agrolog Limited (RC 1527119) is a newly established
                  agribusiness company specialized in production and trade of
                  agricultural commodities, agrologistics, various agricultural
                  investment and farm management. Innovations that assure the
                  delivery of wholesome food to consumers constitute the value
                  proposition which AgroLog brings to its clients. Institutional
                  voids constitute great impediments in food systems, creating
                  chaos and disruptions in the ecosystem. The desire to address
                  this has taken us back to the fundamentals of food production:
                  THE FARM. AgroLog seeks to entrench a culture of operational
                  excellence that guarantees transparency from farm to table.
                  The primary objective is traceability that ensures the
                  consumer not only know the source but the quality of food on
                  the table. Consequently AgroLog has contrived partnerships
                  through Innovation Platforms (IPs) that bring farmers and
                  investors together enabling ecosystems that allow for backward
                  integration that builds farmers capacities, flow of quality
                  inputs, standardization of practices that ensure high
                  productivity and quality outputs. On the forward integration
                  front we work with partner clusters to achieve best practices
                  in postharvest handling that deliver quality to the table. Our
                  initial focus is on vegetables but our network is growing to
                  include commodities across the spectrum of agribusiness.
                </p>
              </v-col>
              <v-col cols="12" md="5" class="d-flex flex-column">
                <!-- <v-avatar size="200" tile color="green"> -->
                <v-img
                  class="ma-4"
                  contain
                  src="../assets/undraw_team_spirit_hrr4.svg"
                ></v-img>
              </v-col>
            </v-row>

            <section class="values">
              <div class="values-wrap container">
                <div class="card-val">
                  <!-- <i class="fas fa-eye fa-3x"></i> -->
                  <img src="../assets/mission.svg" alt="" />

                  <h2>Vision</h2>
                  <p>
                    To innovate for inclusive and sustainable development.
                  </p>
                </div>
                <div class="card-val">
                  <!-- <i class="fas fa-bullseye fa-3x"></i> -->
                  <img src="../assets/vision.svg" alt="" />

                  <h2>Mission</h2>
                  <p>
                    Our mission is to create platforms, opportunities and
                    sustainable agribusinesses that will drive economic growth.
                  </p>
                </div>

                <!-- <div class="card">
                  <i class="fas fa-balance-scale fa-3x"></i>
                  <img src="" alt="">

                  <h2>Values</h2>
                  <p>
                    People - Productivity - Teamwork - Innovation- Quality
                  </p>
                </div> -->
              </div>
            </section>
            <!-- <v-row>
              <v-col cols="12" md="6">
                <h2
                  class="text-h6 font-weight-light pt-4 grey--text text--darken-3 text-center custom__heading-green"
                >
                  OUR MISSION
                </h2>
                <v-row>
                  <v-col cols="12" md="4" class="d-flex flex-column">
                    <v-img contain src="../assets/mission.svg"></v-img>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p
                      class="px-16 text-body-1 text-md-h6 font-weight-light grey--text text--darken-3"
                    >
                      Our mission is to create platforms, opportunities and
                      sustainable agribusinesses that will drive economic
                      growth.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <h2
                  class="text-h6 font-weight-light pt-4 grey--text text--darken-3 text-center custom__heading-orange"
                >
                  OUR VISION
                </h2>
                <v-row>
                  <v-col cols="12" md="8">
                    <p
                      class="px-16 text-body-1 text-md-h6 font-weight-light grey--text text--darken-3"
                    >
                      To innovate for inclusive and sustainable development.
                    </p>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex flex-column">
                    <v-img contain src="../assets/vision.svg"></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-footer dark padless class="grey darken-3 white--text text-center">
      <v-card flat tile class="grey darken-3 white--text text-center container">
        <v-card-text>
          <v-btn
            v-for="item in footerItems"
            :key="item.href"
            class="mx-4 white--text"
            icon
            :href="item.href"
          >
            <v-icon size="24px">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text
          class="white--text pt-0 text-body-2 text-sm-body-1 font-weight-light"
        >
          Agrolog Limited (RC 1527119) is a registered agribusiness
          company specialized in production and trade of agricultural
          commodities, agrologistics, various agricultural investment and farm
          management. Innovations that assure the delivery of wholesome food to
          consumers constitute the value proposition which AgroLog brings to its
          clients.
        </v-card-text>
        <div
          class="text-center white--text pt-0 text-body-2 text-sm-body-1 font-weight-bold"
        >
          <span>Head Office: 31, Gwari Avenue Barnawa Kaduna Nigeria</span>
        </div>
        <v-divider></v-divider>

        <v-card-text class="white--text body-1">
          {{ new Date().getFullYear() }} —
          <strong class="success--text mr-4">Agrolog</strong>
          <span>
            <v-btn small outlined href="https://agrolog.farm:2096/">
              <v-icon class="mr-1">mdi-email</v-icon>
              <span class="text-capitalize">Check Mail</span>
            </v-btn>
          </span>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss">
.custom__card {
  background: linear-gradient(rgba(0, 148, 50, 0.9), rgba(0, 148, 146, 0.7)),
    url("../assets/ginger.jpeg");
  -webkit-background: linear-gradient(
      rgba(0, 148, 50, 0.9),
      rgba(0, 148, 146, 0.7)
    ),
    url("../assets/ginger.jpeg");
  background-size: cover;
  //   background-attachment: fixed;
  height: 150px;
}
.custom__divider {
  width: 110px;
}
.custom__heading-green::after {
  display: block;
  height: 2px;
  width: 100px;
  background-color: #009432;
  content: " ";
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.custom__heading-orange::after {
  display: block;
  height: 2px;
  width: 100px;
  background-color: #f9952d;
  content: " ";
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.custom__heading-white::after {
  display: block;
  height: 2px;
  width: 100px;
  background-color: #ffffff;
  content: " ";
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.values {
  margin-top: 3rem;
}

.values-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  place-items: center;
}

.values-wrap .card-val {
  background-color: #ffff;
  color: var(--text-color);
  padding: 1rem;
  border: 2px solid rgba(77, 77, 77, 0.18);
  min-height: 400px;
  box-shadow: 2px 10px 10px rgba(197, 190, 190, 0.97);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  min-width: 100%;
}

.values-wrap img {
  width: 300px;
  height: 250px;
}
.card-val h2 {
  color: #009432;
}
</style>
